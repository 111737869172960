import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@tegrus/formio-react';
import settingsForm from './Address.settingsForm';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

/**
 * Um exemplo de componente React
 *
 * Substitua isso pelo seu componente de reação personalizado. Precisa ter duas coisas.
 * 1. O valor deve ser armazenado no estado como "value"
 *
 * 2. Quando o valor muda no input ao ser digitado, chama a func setValue(e.target.value);
 * (e.target.value) === texto digitado no input
 *
 *
 * Este componente é muito simples. Quando digitar no campo, ele definirá as sugestões para aparecer na tela.
 */
const AddressCustomComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      street_number: '',
      postal_code: '',
      street: '',
      province: '',
      city: '',
      district: '',
      country: '',
    };

    this.getAddressObject = this.getAddressObject.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
  }

  handleInputs = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      province: ['administrative_area_level_1'],
      city: ['locality'],
      country: ['country'],
    };

    let address = {
      street_number: '',
      postal_code: '',
      street: '',
      province: '',
      city: '',
      district: '',
      country: '',
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country') {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
            address.district = address_components[1].short_name;
            address.city = address_components[2].short_name;
          }
        }
      }
    });
    return address;
  };

  // async componentDidUpdate(prevProps) {
  //   const { address } = this.state;
  //   if (address) {
  //     const geocodeObj =
  //       address?.value && (await geocodeByPlaceId(address.value.place_id));
  //     const addressObject = this.getAddressObject(
  //       geocodeObj[0].address_components
  //     );
  //     this.setState({ ...addressObject });
  //   }
  // }

  render() {
    const { address, street_number, postal_code, street, province, city, district, country } =
      this.state;
    return (
      <div className="App">
        <GooglePlacesAutocomplete
          apiKey="AIzaSyD1ttCoqPWzxCgTWd7B2d248V2QzY1Hg-g"
          selectProps={{
            isClearable: true,
            value: address,
            onChange: (val) => {
              const func = async () => {
                if (val) {
                  const geocodeObj = val?.value && (await geocodeByPlaceId(val.value.place_id));
                  const addressObject = this.getAddressObject(geocodeObj[0].address_components);
                  return this.setState({ ...addressObject });
                }
              };
              func();
            },
            styles: {
              input: (provided) => ({
                ...provided,
                boxShadow: 0,
                '&:hover': {
                  border: '1px solid purple',
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                boxShadow: 0,
                '&:hover': {
                  border: '1px solid purple',
                },
              }),
            },
          }}
        />
        {city && (
          <div>
            <input
              className="form-control mt-2"
              type="text"
              name="city"
              placeholder="Cidade"
              value={city}
              onChange={this.handleInputs}
            />
            <input
              className="form-control mt-2"
              type="text"
              name="country"
              placeholder="País"
              value={country}
              onChange={this.handleInputs}
            />
            <input
              className="form-control mt-2"
              type="text"
              name="street_number"
              placeholder="Número da Casa"
              value={street_number}
              onChange={this.handleInputs}
            />
            <input
              className="form-control mt-2"
              type="text"
              name="postal_code"
              placeholder="CEP"
              value={postal_code}
              onChange={this.handleInputs}
            />
            <input
              className="form-control mt-2"
              type="text"
              name="street"
              placeholder="Nome da rua"
              value={street}
              onChange={this.handleInputs}
            />
            <input
              className="form-control mt-2"
              type="text"
              name="province"
              placeholder="Estado"
              value={province}
              onChange={this.handleInputs}
            />
            <input
              className="form-control mt-2"
              type="text"
              name="district"
              placeholder="Bairro"
              value={district}
              onChange={this.handleInputs}
            />
          </div>
        )}
      </div>
    );
  }
};

export default class AdressComponent extends ReactComponent {
  /**
   * Esta função informa ao construtor de formulários sobre seu componente. Seu nome, ícone e em qual grupo ele deve estar.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Endereço',
      icon: 'home',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: AdressComponent.schema(),
    };
  }

  /**
   * Esta função é a configuração padrão do componente. No mínimo, você deseja definir o tipo para o registrado
   * tipo do seu componente (ou seja, quando você chama Components.setComponent('type', MyComponent) esses tipos devem corresponder.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'addressCustomComp',
      label: 'Default Label',
    });
  }

  /*
   * Define o settingsForm ao editar um componente no construtor.
   */
  static editForm = settingsForm;

  /**
   * Essa função é chamada quando o DIV é renderizado e adicionado ao DOM. Agora você pode instanciar o componente react.
   *
   * @param DOMElement
   * aqui retorna ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <AddressCustomComp
        component={this.component} // Estas são as configurações do componente se você quiser usá-las para renderizar o componente.
        value={this.dataValue} // O valor inicial do componente.
        onChange={this.updateValue} // O evento onChange a ser chamado quando o valor for alterado.
      />,
      element
    );
  }

  /**
   * Separe automaticamente quaisquer componentes de reação.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
