import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalNewForm from '../../Formio/modalNewForm';
import DeleteForm from '../../Reusables/Modal/modalDeleteAlert/Index';
import ModalPublishIsc from '../../Reusables/Modal/modalPublishISC/Index';
import { useForms } from '../../../hooks/useForms';
import { useModal } from '../../../hooks/useModal';

function KebabMenuReusable({ idForm }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showISCModal, setShowISCModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const { deleteForm, form } = useForms(idForm);
  const { openModal, closeModal, isOpen } = useModal();
  const actions = [
    { name: 'Editar' },
    { name: 'Visualizar' },
    { name: 'Renomear' },
    { name: 'Excluir' },
    { name: 'Publicar' },
  ];

  let menuRef = useRef();

  const onActionClick = async (action) => {
    if (action === 'Editar') {
      return navigate(`/form/editar/${idForm}`);
    }
    if (action === 'Visualizar') {
      return navigate(`/form/visualizar/${idForm}`);
    }
    if (action === 'Renomear') {
      return openModal();
    }
    if (action === 'Excluir') {
      setShowModal(true);
    }
    if (action === 'Publicar') {
      setShowISCModal(true);
    }
  };

  const handleDelete = async () => {
    await deleteForm(idForm);
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  });

  return (
    <div className="tw-relative tw-inline-block tw-text-left z-[100]">
      <DeleteForm showModal={showModal} setShowModal={setShowModal} ConfirmDelete={handleDelete} />
      <ModalPublishIsc id={idForm} showModal={showISCModal} setShowModal={setShowISCModal} />
      <ModalNewForm
        currentForm={form}
        key={form.updatedAt}
        isOpen={isOpen}
        closeModal={closeModal}
      />
      <div>
        <button
          className="text-navbar_color"
          onClick={() => setShowOptions(!showOptions)}
          type="button"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-6 tw-w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </button>
      </div>
      {showOptions && (
        <div
          className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
          ref={menuRef}
        >
          <div className="tw-py-1" role="none">
            {actions.map((current) => (
              <a
                className="tw-cursor-pointer tw-text-gray-700 tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-100"
                role="menuitem"
                tabIndex="-1"
                key={current.name}
                onClick={() => onActionClick(current.name)}
                id="menu-item-0"
              >
                {current.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default KebabMenuReusable;
