import { FormBuilder, Components } from '@tegrus/formio-react';
import { useState } from 'react';
import components from '../../../customComponentsFormio';
import { useParams } from 'react-router-dom';
import ModalEditForm from '../modalEditForm/Index';
import { ReactComponent as EditFileIcon } from '../../../assets/images/edit-icon.svg';
import { ReactComponent as SaveFileIcon } from '../../../assets/images/save-icon.svg';
import { useForms } from '../../../hooks/useForms';
import { useModal } from '../../../hooks/useModal';

Components.setComponents(components);

function NewForm() {
  const { openModal, closeModal, isOpen } = useModal();
  const { id } = useParams();
  const { form, upsertForm, integrationSyncForm } = useForms(id);

  const onSave = async (formData) => {
    await upsertForm(formData);
  };

  const onEdit = () => {
    openModal();
  };

  return (
    <>
      <ModalEditForm
        currentForm={form}
        onSave={onSave}
        onIntegrationSync={integrationSyncForm}
        closeModal={closeModal}
        isOpen={isOpen}
        key={`modal-${form.updatedAt}`}
      />
      <NewFormInner
        currentForm={form}
        onSave={onSave}
        onEdit={onEdit}
        key={`form-${form.updatedAt}`}
      ></NewFormInner>
    </>
  );
}

/**
 *
 * This components only exists to sync useState with props, after form operations.
 */
function NewFormInner({ currentForm, onSave, onEdit }) {
  const [form, setForm] = useState(currentForm);

  const handleFormChange = (schema) => {
    setForm({ ...form, components: schema.components });
  };

  return (
    <>
      <div className=" tw-w-11/12 tw-mx-10">
        <div className="title_form">
          <div className="tw-flex tw-justify-between tw-my-5">
            <h1 className="tw-text-navbar_color tw-font-medium">{form.name}</h1>
            <div className="tw-flex tw-items-center">
              <div
                className="tw-h-9 tw-flex tw-justify-center tw-mx-5 tw-items-center tw-px-2 tw-bg-navbar_color tw-rounded tw-text-sm tw-cursor-pointer transition tw-ease-in-out tw-delay-150  hover:tw--translate-y-1 hover:tw-scale-110 tw-fill-white tw-font-semibold hover:tw-fill-primary_color hover:tw-text-primary_color tw-duration-300 tw-text-white"
                onClick={onEdit}
              >
                Editar
                <EditFileIcon onClick={onEdit} className="tw-w-6 tw-ml-3" />
              </div>
              <div
                className="tw-h-9 tw-flex tw-justify-center tw-items-center tw-px-2 tw-bg-navbar_color tw-rounded tw-text-sm tw-cursor-pointer transition tw-ease-in-out tw-delay-150  hover:tw--translate-y-1 hover:tw-scale-110 tw-fill-white tw-font-semibold hover:tw-fill-primary_color hover:tw-text-primary_color tw-duration-300 tw-text-white"
                onClick={() => onSave(form)}
              >
                Salvar
                <SaveFileIcon className="tw-w-6 tw-ml-3 " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormBuilder
        form={{
          display: form.display,
          components: form.components,
        }}
        onChange={(schema) => handleFormChange(schema)}
        options={{
          builder: {
            basic: {
              components: {
                autoCompleteCustomComp: true,
                phoneCustomComp: true,
                addressCustomComp: true,
              },
            },
          },
        }}
      />
    </>
  );
}

export default NewForm;
