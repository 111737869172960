import baseEditForm from '@tegrus/formiojs/components/_classes/component/Component.form';

const component = (...extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          {
            // Você pode ignorar os campos existentes.
            key: 'placeholder',
            ignore: true,
          },
          {
            // Ou adicione seus próprios. A sintaxe são as definições do componente form.io.
            type: 'textfield',
            input: true,
            label: 'My Custom Setting',
            weight: 12,
            key: 'myCustomSetting', // Isso estará disponível como component.myCustomSetting
          },
        ],
      },
      {
        key: 'data',
        components: [],
      },
      {
        key: 'validation',
        components: [],
      },
      {
        key: 'api',
        components: [],
      },
      {
        key: 'conditional',
        components: [],
      },
      {
        key: 'logic',
        components: [],
      },
    ],
    ...extend
  );
};

export default component;
