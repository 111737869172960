import { useEffect, useState } from 'react';
import Context from './MyContext';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { getForms, getIntegrations } from '../services/api';

function MyProvider({ children }) {
  const formsCache =
    localStorage.getItem('forms') !== null && JSON.parse(localStorage.getItem('forms'));
  const { pathname } = useLocation();
  const [clients, setClients] = useState([]);
  const [forms, setForms] = useState(formsCache ? formsCache : []);
  const [edit, setEdit] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [selectedCompany, setSelectCompany] = useState({ name: '', id: '' });
  const [integration, setIntegration] = useState([]);
  const notifySuccess = (message) => toast.success(message);
  const [currentClient, setCurrentClient] = useState({
    name: '',
  });
  const [currentIntegration, setCurrentIntegration] = useState({
    name: '',
    token: '',
  });

  useEffect(() => {
    localStorage.setItem('forms', JSON.stringify(forms));
  }, [forms]);

  useEffect(() => {
    if (pathname === '/login' || !selectedCompany?.id) {
      return;
    }
    const requestForms = async () => {
      const [forms = [], integrations = []] = await Promise.all([
        getForms(selectedCompany.id),
        getIntegrations(selectedCompany.id),
      ]);
      setIntegration(integrations);
      setForms(forms);
    };

    requestForms();
  }, [selectedCompany, pathname]);

  const reveal = {
    clients,
    selectedCompany,
    setSelectCompany,

    // forms
    forms,
    setForms,
    setClients,

    edit,
    setEdit,
    integration,
    setIntegration,
    currentIntegration,
    setCurrentIntegration,
    notifySuccess,
    currentClient,
    setCurrentClient,

    // pages
    activeTab,
    setActiveTab,
  };

  return <Context.Provider value={reveal}>{children}</Context.Provider>;
}

export default MyProvider;
