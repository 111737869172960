import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@tegrus/formio-react';
import settingsForm from './PhoneField.settingsForm';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneCustomComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  setValue = () => {
    this.setState(
      (prevState) => ({ value: !prevState.value }),
      () => this.props.onChange(this.state.value)
    );
  };

  render() {
    const { value } = this.state;
    return (
      <PhoneInput country={'br'} value={value} onChange={(value) => this.setState({ value })} />
    );
  }
};

export default class Phone extends ReactComponent {
  /**
   *
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Telefone',
      icon: 'phone',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: Phone.schema(),
    };
  }

  /**
   *
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'phoneCustomComp',
      label: 'Default Label',
    });
  }

  /*
   *
   */
  static editForm = settingsForm;

  /**
   *
   *
   * @param DOMElement
   *
   */
  attachReact(element) {
    return ReactDOM.render(
      <PhoneCustomComp
        component={this.component}
        value={this.dataValue}
        onChange={this.updateValue}
      />,
      element
    );
  }

  /**
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
