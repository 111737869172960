import { useContext, useMemo } from 'react';
import MyContext from '../Context/MyContext';
import * as api from '../services/api';
import { notify } from '../utils/notify';

const updateForm = (id, changes, forms) => {
  return forms.map((form) => {
    if (form.id === id) {
      return { ...form, ...changes };
    }
    return form;
  });
};

export function useForms(id) {
  const { forms, setForms, selectedCompany } = useContext(MyContext);

  const form = useMemo(() => {
    return forms.find((f) => f.id === id);
  }, [forms, id]);

  const addFormLocal = (form) => {
    setForms([...forms, form]);
  };

  const editFormLocal = ({ id, ...changes }) => {
    const updated = updateForm(id, { ...changes, updatedAt: new Date() }, forms);
    setForms(updated);
  };

  const deleteFormLocal = (id) => {
    setForms(forms.filter((f) => f.id !== id));
  };

  const deleteForm = async (id) => {
    await api.deleteForm(id);
    deleteFormLocal(id);
    notify.success('Formulário excluído com sucesso!');
  };

  const createForm = async (form) => {
    const saved = await api.createForm(selectedCompany.id, form);
    addFormLocal(saved);
    return saved;
  };

  const editForm = async (form) => {
    const saved = await api.editForm(form);
    editFormLocal(saved);
    notify.success('Formulário salvo com sucesso!');
    return saved;
  };

  const integrationSyncForm = async (formId) => {
    try {
      const data = await api.integrationSyncForm(formId);
      notify.success('Pipes sincronizados com sucesso!');
      return data;
    } catch (error) {
      notify.error(error.response?.data?.message);
      throw error;
    }
  };

  const upsertForm = async (form) => {
    const saved = await (form.id ? editForm(form) : createForm(form));
    return saved;
  };

  return {
    // local state
    forms,
    form,

    // api
    createForm,
    editForm,
    integrationSyncForm,
    deleteForm,
    upsertForm,
  };
}
