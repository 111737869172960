import React from 'react';

function LabelReusable({ name }) {
  return (
    <label
      className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2 tw-ml-3"
      htmlFor="username"
    >
      {name}
    </label>
  );
}

export default LabelReusable;
