import HorizontalNavbarReusable from '../Reusables/Navbar/HorizontalNavbar';
import { useModal } from '../../hooks/useModal';
import { useContext } from 'react';
import ModalNewClient from '../Clients/modalNewClient/Index';
import TableItemsClients from '../Clients/TableItemsClients';
import MyContext from '../../Context/MyContext';

function Clients() {
  const { clients } = useContext(MyContext);
  const { openModal, isOpen, closeModal } = useModal();

  return (
    <>
      <HorizontalNavbarReusable onButtonClick={() => openModal()} />

      <ModalNewClient isOpen={isOpen} closeModal={closeModal} />

      <section className="tw-py-6 tw-ml-8">
        <div className="tw-container  tw-my-3 tw-rounded-2xl tw-shadow-md ">
          <div className="tw-p-4 tw-bg-white ">
            <table className="tw-table-auto tw-w-full">
              <thead className="tw-border-navbar_color tw-border-b">
                <tr className="tw-text-xs tw-text-gray-500 ">
                  <th className="tw-pb-3 tw-font-medium tw-pl-7">Nome</th>
                  <th className="tw-pb-3 tw-font-medium tw-ml-20 tw-align-center">Ações</th>
                </tr>
              </thead>

              <tbody>
                {clients.length > 0 &&
                  clients.map((client) => (
                    <TableItemsClients
                      key={client.id}
                      id={client.id}
                      name={client.name}
                      openModal={openModal}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Clients;
