import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonReusable from '../../Reusables/Button/Button';
import InputReusable from '../../Reusables/Input/Input';
import ModalComponent from '../../Reusables/Modal/Index';
import { NameForm } from '../../../assets/images/exportSvg';
import { useForms } from '../../../hooks/useForms';
import { defaultForm } from '../../../utils/settingsDefaultForm';

function ModalNewForm({ currentForm, isOpen, closeModal }) {
  const navigate = useNavigate();
  const [form, setForm] = useState(currentForm ?? defaultForm);
  const { upsertForm } = useForms();
  const isNewForm = !currentForm;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const submitNewFormModal = async () => {
    if (form.name.length < 4) return;

    const { id } = await upsertForm(form);

    setForm(defaultForm);

    if (isNewForm) {
      navigate(`/form/editar/${id}`);
    }

    closeModal();
  };

  return (
    <ModalComponent title="Criando o seu Formulário" isOpen={isOpen} closeModal={closeModal}>
      <div className="tw-flex tw-flex-col">
        <div className="tw-mb-6 tw-flex tw-p-4 tw-bg-primary_color_light tw-rounded">
          <InputReusable
            type="text"
            name="name"
            value={form.name}
            onChange={handleOnChange}
            placeholder="Digite o nome do seu formulario"
            className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
          />
          <img className="tw-h-6 tw-w-6 tw-ml-4 tw-my-auto tw-text-gray-300" src={NameForm} />
        </div>
        <ButtonReusable
          text="Criar"
          onClick={submitNewFormModal}
          className="tw-mb-2 tw-w-full tw-py-4 tw-bg-navbar_color hover:tw-bg-primary_color tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200"
        />
      </div>
    </ModalComponent>
  );
}

export default ModalNewForm;
