import { LogoISC } from '../../../assets/images/exportSvg';

function NavbarMobileReusable() {
  return (
    <nav className="lg:tw-hidden tw-py-6 tw-px-6 tw-bg-gray-800">
      <div className="tw-flex tw-items-center tw-justify-between">
        <a className="tw-text-2xl tw-text-white tw-font-semibold" href="#">
          <img className="tw-h-10" src={LogoISC} width="auto" />
        </a>
      </div>
    </nav>
  );
}
export default NavbarMobileReusable;
