import { useLocation } from 'react-router-dom';
import { IntegrationIconDelete } from '../../../../assets/images/exportSvg';
import ButtonReusable from '../../Button/Button';

export default function DeleteModal({ showModal, setShowModal, ConfirmDelete }) {
  const { pathname } = useLocation();

  const routes = {
    '/clientes': { name: 'esse cliente?' },
    '/integracoes': { name: 'essa integração?' },
    '/': { name: 'esse formulário?' },
  };

  const cancelButton = () => {
    setShowModal(false);
  };

  const confirmButton = () => {
    ConfirmDelete();
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="tw-justify-center tw-items-center tw-flex tw-overflow-x-hidden tw-overflow-y-auto tw-fixed tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none">
            <div className="tw-relative tw-w-auto tw-my-6 tw-mx-auto tw-max-w-3xl">
              <div className="tw-bg-white tw-rounded tw-shadow-lg tw-border tw-flex tw-flex-col tw-overflow-hidden tw-px-10 tw-py-10">
                <div className="tw-text-center tw-flex tw-justify-center">
                  <img src={IntegrationIconDelete} className="tw-w-20 tw-h-20" />
                </div>
                <div className="tw-text-center tw-font-light tw-mb-8 tw-mt-10">
                  {`Você tem certeza que deseja remover ${routes[pathname]?.name}`}
                </div>
                <div className="tw-flex tw-justify-center">
                  <ButtonReusable
                    onClick={cancelButton}
                    className="tw-cursor-pointer tw-flex tw-items-center tw-py-2 tw-px-4 tw-mr-3 tw-rounded tw-bg-[#6b7280] hover:tw-bg-[#565B66] tw-text-sm tw-font-medium"
                    text="Cancelar"
                  />
                  <ButtonReusable
                    onClick={confirmButton}
                    className="tw-cursor-pointer tw-flex tw-items-center tw-py-2 tw-px-4 tw-mr-3 tw-rounded tw-bg-red-500 hover:tw-bg-[#B03131] tw-text-sm tw-font-medium"
                    text="Deletar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 tw-bg-black"></div>
        </>
      ) : null}
    </>
  );
}
