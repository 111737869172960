import { notify } from '../../utils/notify';
import { useContext } from 'react';
import { useModal } from '../../hooks/useModal';
import HorizontalNavbarReusable from '../Reusables/Navbar/HorizontalNavbar';

import ModalNewIntegration from './modalNewIntegration/Index';
import MyContext from '../../Context/MyContext';
import TableItemsIntegration from './TableItemsIntegration';

function Integrations() {
  const { integration } = useContext(MyContext);
  const { openModal, isOpen, closeModal } = useModal();

  const onEnterEditModel = () => {
    openModal();
  };

  const onIntegrationDeleted = () => {
    notify.success('Integração deletada com sucesso!');
    closeModal();
  };

  return (
    <>
      <HorizontalNavbarReusable onButtonClick={() => openModal()} />

      <ModalNewIntegration isOpen={isOpen} closeModal={closeModal} />

      <section className="tw-py-6 tw-ml-8">
        <div className="tw-container  tw-my-3 tw-rounded-2xl tw-shadow-md ">
          <div className="tw-p-4 tw-bg-white ">
            <table className="tw-table-auto tw-w-full">
              <thead className="tw-border-navbar_color tw-border-b">
                <tr className="tw-text-xs tw-text-gray-500 ">
                  <th className="tw-pb-3 tw-font-medium tw-pl-7">Nome</th>
                  <th className="tw-pb-3 tw-font-medium tw-ml-20 tw-align-center">Ações</th>
                </tr>
              </thead>

              <tbody>
                {integration.length > 0 &&
                  integration.map((inte) => (
                    <TableItemsIntegration
                      key={inte.id}
                      id={inte.id}
                      name={inte.name}
                      idPipefy={inte.idPipefyUser}
                      onEnterEditModel={onEnterEditModel}
                      onIntegrationDeleted={onIntegrationDeleted}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Integrations;
