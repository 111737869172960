import { copy } from '../../../assets/images/exportSvg';
import KebabMenuReusable from '../KebabMenu/KebabMenu.jsx';
import moment from 'moment';

function CardReusable({ name, createdAt, enable, id, updatedAt, submissionsCount }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
  };

  return (
    <>
      <div className="tw-w-full lg:tw-w-1/3 tw-p-4">
        <div className="tw-p-4 tw-bg-white tw-shadow-md tw-rounded-2xl">
          <div className="tw-flex tw-mb-6 tw-justify-between tw-items-center">
            <strong className="tw-text-lg tw-font-medium">{name}</strong>
            <KebabMenuReusable idForm={id} />
          </div>
          <div className="tw-flex tw-mb-2 tw-justify-between tw-items-center">
            <h4 className="tw-text-xs tw-font-medium">Data de início:</h4>
            <span className="tw-inline-block tw-py-1 tw-px-2 tw-rounded-full tw-bg-green-50 tw-text-xs tw-text-green-500">
              {moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
          <div className="tw-flex tw-mb-2 tw-justify-between tw-items-center">
            <h4 className="tw-text-xs tw-font-medium">Última atualização:</h4>
            <span className="tw-inline-block tw-py-1 tw-px-2 tw-rounded-full tw-bg-red-50 tw-text-xs tw-text-red-500">
              {moment(updatedAt).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
          <div className="tw-flex tw-mb-2 tw-justify-between tw-items-center">
            <h4 className="tw-text-xs tw-font-medium text-primary_color">Respostas:</h4>
            <span className="tw-inline-block tw-py-1 tw-px-2 tw-rounded-full tw-bg-primary_color_light tw-text-xs tw-text-primary_color">
              {submissionsCount}
            </span>
          </div>
          <div className="tw-flex tw-mb-1 tw-justify-between tw-items-center">
            <span className="tw-text-xs tw-bg-primary_color_light tw-rounded tw-p-0.5">{id}</span>
            <img src={copy} className="tw-w-4 tw-cursor-pointer" onClick={copyToClipboard} />
          </div>
        </div>
      </div>
    </>
  );
}

export default CardReusable;
