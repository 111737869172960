export const NavbarButtons = [
  {
    module: 'PRINCIPAL',
    items: [
      {
        route: '/',
        tabName: 'Formulários',
        actionButtonName: 'Adicionar Formulário',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" className="options_icons tw-text-white" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
      </svg>`,
      },
      {
        route: '/integracoes',
        tabName: 'Integrações',
        actionButtonName: 'Adicionar Integração',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" className="options_icons tw-text-white" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
      </svg>`,
      },
    ],
  },
  {
    module: 'ADMINISTRATIVO',
    items: [
      {
        route: '/convidar',
        tabName: 'Convidar',
        actionButtonName: null,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" className="options_icons tw-text-white" viewBox="0 0 20 20" fill="currentColor">
        <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
      </svg>`,
      },
      {
        route: '/clientes',
        tabName: 'Clientes',
        actionButtonName: 'Registrar Cliente',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" className="options_icons tw-text-white" viewBox="0 0 20 20" fill="currentColor">
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>`,
      },
    ],
  },
  {
    module: 'CONFIGURAÇÕES AVANÇADAS',
    items: [
      {
        route: '/configuracoes',
        tabName: 'Configurações',
        actionButtonName: null,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" className="options_icons tw-text-white" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
      </svg>`,
      },
    ],
  },
];
