import axios from 'axios';
import Cookies from 'js-cookie';
const tokenCookie = Cookies.get('session');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const login = async (email, password) => {
  try {
    const {
      data: { token, user },
    } = await api.post(`/auth/login`, { email, password });
    Cookies.set('session', token, { expires: 7 });
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return { user };
  } catch (error) {
    return { error };
  }
};

const logout = async () => {
  try {
    Cookies.remove('session');
    window.location.replace('/');
  } catch (error) {
    throw error;
  }
};

if (tokenCookie) {
  api.defaults.headers.common['Authorization'] = `Bearer ${tokenCookie}`;
}

const getClients = async () => {
  try {
    const { data } = await api.get(`/clients`);
    return data;
  } catch (error) {
    return error;
  }
};

const getForms = async (client) => {
  try {
    const { data } = await api.get(`/forms?client=${client}`);
    return data;
  } catch (error) {
    return error;
  }
};

const createForm = async (client, body) => {
  try {
    const { data } = await api.post(`/forms?client=${client}`, { ...body, idClient: client });
    return data;
  } catch (error) {
    return error;
  }
};

const deleteForm = async (id) => {
  try {
    await api.delete(`/forms/${id}`);
  } catch (error) {
    return error;
  }
};

const removeEmpty = (obj) => {
  return Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
};

const editForm = async (form) => {
  try {
    removeEmpty(form);
    const { data } = await api.put(`/forms/${form.id}`, form);
    return data;
  } catch (error) {
    return console.log(error.message);
  }
};

const integrationSyncForm = async (formId) => {
  const { data } = await api.put(`/forms/${formId}/integration/sync`);
  return data;
};

const getIntegrations = async (client) => {
  try {
    const { data } = await api.get(`/integrations/pipefy?client=${client}`);
    return data;
  } catch (error) {
    return console.log(error.message);
  }
};

const createIntegration = async (body) => {
  try {
    const { data } = await api.post(`/integrations/pipefy`, body);
    return data;
  } catch (error) {
    return console.log(error.message);
  }
};

const updateIntegration = async (id, body) => {
  try {
    await api.put(`/integrations/pipefy/${id}`, body);
  } catch (error) {
    return console.log(error.message);
  }
};

const deleteIntegration = async (id) => {
  try {
    await api.delete(`/integrations/pipefy/${id}`);
  } catch (error) {
    return console.log(error.message);
  }
};

const createClient = async (body) => {
  try {
    const { data } = await api.post(`/clients`, body);
    return data;
  } catch (error) {
    return console.log(error.message);
  }
};

const clientRelation = async (idUser, idClient) => {
  try {
    const { data } = await api.post(`/users/${idUser}/relations/clients`, {
      idClient,
    });
    return data;
  } catch (error) {
    return console.log(error.message);
  }
};

const updateClient = async (id, body) => {
  try {
    await api.put(`/clients/${id}`, body);
  } catch (error) {
    return console.log(error.message);
  }
};

const deleteClient = async (id) => {
  try {
    await api.delete(`/clients/${id}`);
  } catch (error) {
    return console.log(error.message);
  }
};

const getEnvironment = async () => {
  try {
    const { data } = await api.get(`/env`);
    return data;
  } catch (error) {
    console.error(error.message);
  }
};

// test
// id = d339d446-485c-4b85-8b3c-06489cd8fd7b
// idAgency = 5f324fa6-37c8-4dcd-9f5b-0bb9a0e8eab8

export {
  login,
  logout,
  getClients,
  getForms,
  editForm,
  createForm,
  deleteForm,
  integrationSyncForm,
  createIntegration,
  getIntegrations,
  deleteIntegration,
  updateIntegration,
  createClient,
  updateClient,
  deleteClient,
  removeEmpty,
  clientRelation,
  getEnvironment,
};
