import { InviteBanner } from '../../assets/images/exportSvg';
import ButtonReusable from '../Reusables/Button/Button';
import InputReusable from '../Reusables/Input/Input';

function Invite() {
  return (
    <section className="tw-py-8 tw-bg-slate-50">
      <div className="tw-container tw-px-4 tw-mx-auto">
        <div className="tw-relative tw-p-10 xl:tw-py-12 xl:tw-px-20 tw-bg-navbar_color tw-overflow-hidden tw-rounded-2xl">
          <img
            className="tw-absolute tw-right-10 tw-top-0 sm:tw-top-1/2 tw-transform tw--translate-y-1/3 tw-w-1/2 sm:tw-w-2/3 lg:tw-w-auto tw-mt-12 sm:tw-mt-0"
            src={InviteBanner}
          />
          <div className="tw-relative tw-z-10 sm:tw-max-w-sm">
            <h3 className="tw-mb-4  tw-text-white font-heading tw-font-semibold">
              Convidar para o ISC
            </h3>
            <div className="tw-mb-3 tw-flex tw-p-4  tw-bg-primary_color_light tw-roundedd">
              <InputReusable
                className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                type="email"
                name="email"
                placeholder="Nome"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="options_icons tw-text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <ButtonReusable
              text="Convidar"
              type="button"
              className="tw-mb-2 tw-w-full tw-py-4 tw-bg-primary_color hover:tw-bg-primary_color_shadow tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Invite;
