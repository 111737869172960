function InputReusable({ type, value, onChange, placeholder, name, className }) {
  return (
    <input
      type={type}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

export default InputReusable;
