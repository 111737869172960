import { LogoISC } from '../../../assets/images/exportSvg';
import logoTegrus from '../../../assets/images/logo-tegrus-32x32-black.png';
import { logout } from '../../../services/api';
import './Navbar.scss';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { NavbarButtons } from '../../../types/NavbarButtons';
import { useTabs } from '../../../hooks/useTabs';

function NavbarReusable() {
  const { setActiveTab } = useTabs();
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

  const setActiveTabName = (isActive, menuItem) => {
    setTimeout(() => isActive && setActiveTab(menuItem));
    return isActive ? 'is_active' : 'inactive';
  };
  return (
    <>
      <nav className="tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-flex tw-flex-col tw-w-3/4 lg:tw-w-80 sm:tw-max-w-xs tw-pt-6 tw-pb-8 tw-bg-navbar_color tw-overflow-y-auto">
        <div className="tw-flex tw-w-full tw-items-center tw-px-6 tw-pb-6 tw-mb-6 lg:tw-border-b tw-border-primary_color_light">
          <a
            className="tw-flex tw-text-xl tw-text-white tw-font-semibold hover:tw-no-underline hover:tw-text-white"
            href="/"
          >
            <img className="tw-h-8" src={LogoISC} alt="" width="auto" />
            <h3 className="tw-ml-3 ">ISC</h3>
          </a>
        </div>
        <div className="tw-px-4 tw-pb-6">
          {NavbarButtons.map((modules) => (
            <div key={`module-${modules.module}`}>
              <h3 className="tw-mb-2 tw-text-xs tw-uppercase tw-text-gray-500 tw-font-medium tw-cursor-default">
                {modules.module}
              </h3>
              <ul className="tw-mb-8 tw-text-sm tw-font-medium">
                {modules.items.map((menuItem) => (
                  <NavLink
                    to={menuItem.route}
                    className={({ isActive }) => setActiveTabName(isActive, menuItem)}
                    key={`menu-item-${menuItem.tabName}`}
                  >
                    <>
                      <span
                        className="tw-inline-block tw-mr-3"
                        dangerouslySetInnerHTML={{ __html: menuItem.icon }}
                      ></span>
                      <span>{menuItem.tabName}</span>
                      <span className="tw-inline-block tw-ml-auto"></span>
                    </>
                  </NavLink>
                ))}
              </ul>
            </div>
          ))}

          <ul className="tw-text-sm tw-font-medium">
            {user ? (
              <li>
                <div className="tw-ml-1.5 tw-flex tw-align-center tw-items-center tw-rounded tw-text-gray-50 hover:tw-no-underline tw-cursor-default">
                  <img
                    className="tw-w-8 tw-h-8 tw-rounded-full tw-object-cover"
                    src={logoTegrus}
                    alt=""
                  />
                  <div className="tw-ml-3 tw-flex tw-flex-col">
                    <strong className="tw-text-white tw-text-lg">{user.name}</strong>
                    <span className="tw-text-gray-500">{user.email}</span>
                  </div>
                </div>
              </li>
            ) : (
              <div></div>
            )}

            <li>
              <span
                className="tw-flex tw-items-center tw-pl-4 tw-py-3 tw-pr-4 tw-rounded tw-text-red-500 hover:tw-text-white hover:tw-no-underline; hover:tw-bg-red-200 hover:tw-cursor-pointer"
                onClick={logout}
              >
                <span className="tw-inline-block tw-mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="options_icons tw-text-red-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span className="tw-text-red-500">Sair</span>
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavbarReusable;
