import ModalComponent from '../../Reusables/Modal/Index';
import InputReusable from '../../Reusables/Input/Input';
import { handleLogin } from '../../../utils/UtilsLogin';
import { createIntegration, updateIntegration } from '../../../services/api';
import BtnReusable from '../../Reusables/Button/Button';
import { useContext } from 'react';
import MyContext from '../../../Context/MyContext';
import LabelReusable from '../../Reusables/Label/Label';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ModalNewIntegration({ isOpen, closeModal }) {
  const {
    integration,
    setIntegration,
    selectedCompany,
    edit,
    currentIntegration,
    setCurrentIntegration,
    notifySuccess,
  } = useContext(MyContext);

  const handleInfosIntegration = (e) => {
    handleLogin(e, currentIntegration, setCurrentIntegration);
  };

  const submitIntegration = async () => {
    if (edit) {
      const { name, token, id } = currentIntegration;
      const findIndexIntegration = integration.findIndex((inte) => inte.id === id);
      setCurrentIntegration(integration.splice(findIndexIntegration, 1, currentIntegration));
      await updateIntegration(id, { token, name });
      notifySuccess('Integração editada com sucesso!');
      return closeModal();
    }

    const data = await createIntegration({
      ...currentIntegration,
      idClient: selectedCompany.id,
    });
    setIntegration([...integration, data]);
    notifySuccess('Integração criada com sucesso!');
    closeModal();
  };

  return (
    <>
      <ToastContainer />
      <ModalComponent
        title={edit ? 'Editar Integração' : 'Adicionar Integração'}
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <LabelReusable name="Título" />
        <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
          <InputReusable
            type="text"
            name="name"
            value={currentIntegration.name}
            className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
            placeholder="Nome"
            onChange={handleInfosIntegration}
          />
        </div>
        <LabelReusable name="Token" />
        <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
          <InputReusable
            type="text"
            name="token"
            value={currentIntegration.token}
            className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
            placeholder="Token"
            onChange={handleInfosIntegration}
          />
        </div>
        <BtnReusable
          text={edit ? 'Editar Integração' : 'Criar Integração'}
          onClick={submitIntegration}
          className="tw-mb-2 tw-w-full tw-py-4 tw-bg-navbar_color hover:tw-bg-primary_color tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200"
        />
      </ModalComponent>
    </>
  );
}

export default ModalNewIntegration;
