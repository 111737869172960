import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@tegrus/formio-react';
import settingsForm from './AutoComplete.settingsForm';

/**
 * Um exemplo de componente React
 *
 * Substitua isso pelo seu componente de reação personalizado. Precisa ter duas coisas.
 * 1. O valor deve ser armazenado no estado como "value"
 *
 * 2. Quando o valor muda no input ao ser digitado, chama a func setValue(e.target.value);
 * (e.target.value) === texto digitado no input
 *
 *
 * Este componente é muito simples. Quando digitar no campo, ele definirá as sugestões para aparecer na tela.
 */
const AutoCompleteCustomComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      users: [],
      suggestions: [],
    };
  }

  async componentDidMount(prevProps) {
    const response = await fetch('https://reqres.in/api/users').then((data) => data.json());
    this.setState({
      users: response.data,
    });
  }

  setValue = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = this.state.users.filter((usr) => {
        const regex = new RegExp(`${text}`, 'gi');
        return usr.email.match(regex);
      });
    }
    this.setState({ suggestions: matches });
    this.setState({ value: text });
  };

  setSuggestion = (text) => {
    this.setState({ value: text });
    this.setState({ suggestions: [] });
  };

  render() {
    const { suggestions, value } = this.state;
    return (
      <label>
        <input type="text" value={value} onChange={(e) => this.setValue(e.target.value)} />
        {suggestions &&
          suggestions.map((suggestion, i) => (
            <p key={i} onClick={(e) => this.setSuggestion(suggestion.email)}>
              {suggestion.email}
            </p>
          ))}
      </label>
    );
  }
};

export default class AutoComplete extends ReactComponent {
  /**
   * Esta função informa ao construtor de formulários sobre seu componente. Seu nome, ícone e em qual grupo ele deve estar.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Aeroportos',
      icon: 'square',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: AutoComplete.schema(),
    };
  }

  /**
   * Esta função é a configuração padrão do componente. No mínimo, você deseja definir o tipo para o registrado
   * tipo do seu componente (ou seja, quando você chama Components.setComponent('type', MyComponent) esses tipos devem corresponder.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'autoCompleteCustomComp',
      label: 'Default Label',
    });
  }

  /*
   * Define o settingsForm ao editar um componente no construtor.
   */
  static editForm = settingsForm;

  /**
   * Essa função é chamada quando o DIV é renderizado e adicionado ao DOM. Agora você pode instanciar o componente react.
   *
   * @param DOMElement
   * aqui retorna ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <AutoCompleteCustomComp
        component={this.component} // Estas são as configurações do componente se você quiser usá-las para renderizar o componente.
        value={this.dataValue} // O valor inicial do componente.
        onChange={this.updateValue} // O evento onChange a ser chamado quando o valor for alterado.
      />,
      element
    );
  }

  /**
   * Separe automaticamente quaisquer componentes de reação.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
