import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formio } from '@tegrus/formiojs';
import { useForms } from '../../hooks/useForms';

function FormRenderer() {
  const { id } = useParams();
  const { form: currentForm } = useForms(id);

  const [pagesHistory, setPagesHistory] = useState([0]);
  const [submission, setSubmission] = useState({});
  const [form, setForm] = useState(null);

  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);

  const resetSubmission = () => {
    setPagesHistory([0]);
    setSubmission({});
  };

  useEffect(() => {
    const render = async () => {
      try {
        const element = document.getElementById('formio');

        const options = {
          hooks: {
            beforeCancel: () => {
              resetSubmission();
              return true;
            },
          },
          breadcrumbSettings: {
            clickable: true,
          },
          noAlerts: true,
          buttonSettings: {
            showCancel: true,
            showPrevious: true,
            showSubmit: true,
          },
          language: 'pt-BR',
          i18n: {
            'pt-BR': {
              alertMessage: '{{label}}: {{message}}',
              april: 'abril',
              array: '{{field}} deve ser um Array',
              array_nonempty: '{{field}} deve ser um Array não vazio',
              august: 'agosto',
              cancel: 'cancelar',
              Cancel: 'Cancelar',
              complete: 'Submissão completa',
              confirmCancel: 'Você tem certeza que deseja cancelar?',
              day: 'dia',
              days: 'dias',
              Days: 'Dias',
              december: 'dezembro',
              error: 'Por favor corrija os erros antes de enviar o formulário.',
              february: 'fevereiro',
              invalid_date: '{{field}} precisa ser um formato de data válido.',
              invalid_day: '{{field}} precisa ser um dia válido.',
              invalid_email: '{{field}} precisa ser um e-mail válido.',
              invalid_regex: '{{field}} não corresponde ao padrão {{regex}}.',
              invalid_url: '{{field}} precisa ser uma URL válida.',
              january: 'janeiro',
              july: 'julho',
              june: 'junho',
              march: 'março',
              mask: '{{field}} não corresponde à máscara.',
              max: '{{field}} não pode ser maior que {{max}}.',
              maxDate: '{{field}} não deveria conter data maior que {{- maxDate}}',
              maxLength: '{{field}} não deve ter mais do que {{length}} caracteres.',
              maxWords: '{{field}} não deve ter mais do que {{length}} palavras.',
              maxYear: '{{field}} não deveria conter ano maior do que {{maxYear}}',
              may: 'maio',
              min: '{{field}} não pode ser menor do que {{min}}.',
              minDate: '{{field}} não deveria conter data menor do que {{- minDate}}',
              minLength: '{{field}} precisa ter pelo menos {{length}} caracteres.',
              minWords: '{{field}} precisa ter pelo menos {{length}} palavras.',
              minYear: '{{field}} não deveria conter ano menor do que {{minYear}}',
              month: 'mês',
              next: 'próximo',
              Next: 'Próximo',
              nonarray: '{{field}} não pode ser um Array',
              november: 'novembro',
              october: 'outubro',
              pattern: '{{field}} não corresponde ao padrão {{pattern}}',
              previous: 'anterior',
              Previous: 'Anterior',
              required: '{{field}} é obrigatório',
              restoreDraftInstanceError:
                'Não foi possível restaurar rascunho pois não existe instância existente do formio',
              saveDraftAuthError: 'O usuário precisa estar autenticado para salvar rascunho',
              saveDraftInstanceError:
                'Não foi possível salvar rascunho pois não existe instância existente do formio',
              select: '{{field}} contém seleção ou seleções inválida(s)',
              september: 'setembro',
              stripe: '{{stripe}}',
              submit: 'enviar',
              Submit: 'Enviar',
              submitError: 'Por favor cheque o formuário e corrija todos os erros antes de enviar.',
              time: 'Horário inválido',
              unique: '{{field}} precisa ser único',
              valueIsNotAvailable: '{{ field }} é um valor inválido.',
              year: 'ano',
              years: 'anos',
              Years: 'Anos',
              password: 'senha',
              Password: 'Senha',
              Day: 'Dia',
              Month: 'Mês',
              Year: 'Ano',
              January: 'Janeiro',
              February: 'Fevereiro',
              March: 'Março',
              April: 'Abril',
              May: 'Maio',
              June: 'Junho',
              July: 'Julho',
              August: 'Agosto',
              September: 'Setembro',
              October: 'Outubro',
              November: 'Novembro',
              December: 'Dezembro',
            },
          },
        };

        const formio = await Formio.createForm(element, currentForm, options);

        await formio.setSubmission({ ...submission });

        formio.setPage(pagesHistory[pagesHistory.length - 1]);

        formio.on('nextPage', async (context) => {
          setNext(context);
        });

        formio.on('prevPage', async (context) => {
          setPrevious(context);
        });

        setForm(formio);
      } catch (err) {
        console.error(err);
      }
    };

    render();
  }, []);

  useEffect(() => {
    if (next) {
      form.setPage(next.page);

      next.submission.metadata = {
        ...next.submission.metadata,
        currentPageIndex: next.page,
      };

      next.submission = {
        idForm: currentForm.id,
        ...next.submission,
        data: {
          ...next.submission.data,
          submission: submission?._id || submission?.id,
        },
      };

      if (submission) {
        next.submission._id = submission.id;
      }

      setPagesHistory((prev) => [...prev, next.page]);
      setSubmission(next.submission);
    }
  }, [next]);

  useEffect(() => {
    if (previous) {
      form.setPage(pagesHistory[pagesHistory.length - 2]);

      previous.submission.metadata = {
        ...previous.submission.metadata,
        currentPageIndex: pagesHistory[pagesHistory.length - 2],
      };

      setPagesHistory((prev) => prev.slice(0, -1));
      setSubmission(previous.submission);
    }
  }, [previous]);

  return (
    <div className="tw-w-11/12 tw-mx-10 tw-mt-10">
      <div id="formio"></div>
    </div>
  );
}

export default FormRenderer;
