import { useContext, useEffect, useRef, useState } from 'react';
import MyContext from '../../../Context/MyContext';
import saveCompanyInLocal from '../../../utils/saveCompanie';

function DropdownReusable() {
  const { clients, selectedCompany, setSelectCompany } = useContext(MyContext);
  const [showOptions, setShowOptions] = useState(false);

  const clickCompanie = (agency) => {
    setShowOptions(false);
    setSelectCompany({ ...agency });
  };

  let menuRef = useRef();

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  });

  return (
    <div className="tw-relative tw-inline-block tw-text-left">
      <div>
        <button
          onClick={() => setShowOptions(!showOptions)}
          type="button"
          className="tw-text-text_color tw-inline-flex tw-items-center tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 "
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {selectedCompany.name}
          <svg
            className="tw--mr-1 tw-ml-2 tw-h-5 tw-w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {showOptions && (
        <div
          className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
          ref={menuRef}
        >
          <div className="tw-py-1" role="none">
            {clients
              .filter(({ name }) => name !== selectedCompany.name)
              .map((current) => (
                <a
                  key={current.id}
                  className="hover:tw-bg-gray-100 tw-cursor-pointer tw-text-gray-700 tw-block tw-px-4 tw-py-2 tw-text-sm"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  onClick={() => {
                    clickCompanie(current);
                    saveCompanyInLocal(current);
                  }}
                >
                  {current.name}
                </a>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownReusable;
