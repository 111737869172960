import NavbarReusable from '../Reusables/Navbar/Navbar.jsx';
import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getClients, getEnvironment } from '../../services/api.js';
import { useContext, useEffect } from 'react';
import MyContext from '../../Context/MyContext.js';
import NavbarMobileReusable from '../Reusables/Navbar/NavbarMobile.jsx';
import { ToastContainer } from 'react-toastify';

function Home() {
  const { setClients, setSelectCompany } = useContext(MyContext);
  const lastCompanySelected = Cookies.get('company') && JSON.parse(Cookies.get('company'));

  useEffect(() => {
    const response = async () => {
      const result = await getClients();
      const { name, id, idAgency } = result[0];
      setClients(result);
      setSelectCompany(lastCompanySelected ? lastCompanySelected : { name, id, idAgency });

      const env = await getEnvironment()
      localStorage.setItem('env', JSON.stringify(env))
    };
    response();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="tw-antialiased tw-bg-body tw-text-body tw-font-body">
        <div className="">
          <div>
            <NavbarMobileReusable />
            <div className="tw-hidden lg:tw-block tw-navbar-menu tw-relative tw-z-50">
              <NavbarReusable />
            </div>
            <div className="tw-mx-auto lg:tw-ml-80">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
