import ModalComponent from '../../Reusables/Modal/Index';
import InputReusable from '../../Reusables/Input/Input';
import { handleLogin } from '../../../utils/UtilsLogin';
import { createClient, updateClient } from '../../../services/api';
import BtnReusable from '../../Reusables/Button/Button';
import { useContext } from 'react';
import MyContext from '../../../Context/MyContext';
import LabelReusable from '../../Reusables/Label/Label';

function ModalNewClient({ isOpen, closeModal }) {
  const {
    clients,
    setClients,
    selectedCompany,
    edit,
    currentClient,
    notifySuccess,
    setCurrentClient,
  } = useContext(MyContext);

  const handleInfosClient = (e) => {
    handleLogin(e, currentClient, setCurrentClient);
  };

  const submitClient = async () => {
    if (edit) {
      const { id, name } = currentClient;
      const findIndexClient = clients.findIndex((inte) => inte.id === id);
      setCurrentClient(clients.splice(findIndexClient, 1, currentClient));
      await updateClient(id, { name });
      notifySuccess('Cliente editado com sucesso!');
      return closeModal();
    }
    const { idAgency } = selectedCompany;
    const { name } = currentClient;
    const data = await createClient({ idAgency, name });
    setClients([...clients, data]);
    closeModal();
    notifySuccess('Cliente criado com sucesso!');
  };

  return (
    <ModalComponent
      title={edit ? 'Editar Cliente' : 'Adicionar Cliente'}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <LabelReusable name="Nome" />
      <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
        <InputReusable
          type="text"
          name="name"
          value={currentClient.name}
          className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
          placeholder="Nome"
          onChange={handleInfosClient}
        />
      </div>

      <BtnReusable
        text={edit ? 'Editar Cliente' : 'Criar Cliente'}
        onClick={submitClient}
        className="tw-mb-2 tw-w-full tw-py-4 tw-bg-navbar_color hover:tw-bg-primary_color tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200"
      />
    </ModalComponent>
  );
}

export default ModalNewClient;
