import * as yup from 'yup';

const editFormValidation = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Digite pelo menos 3 caracteres')
    .required('Campo "título" tem preenchimento obrigatório'),
  submissionDelay: yup
    .number()
    .typeError('Somente números')
    .min(4)
    .required('Campo "atraso em ms" tem preenchimento obrigatório'),
  retryDelay: yup
    .number()
    .typeError('Somente números')
    .min(4)
    .required('Campo "atraso de repetição" tem preenchimento obrigatório'),
});

export default editFormValidation;
