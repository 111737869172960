import { useState } from 'react';
import {
  LogoISC,
  LoginIllustration,
  PasswordEye,
  PasswordEyeOff,
  EmailIcon,
} from '../../assets/images/exportSvg';
import { login } from '../../services/api';
import { handleLogin, emailOrPassWrong } from '../../utils/UtilsLogin';
import ButtonReusable from '../Reusables/Button/Button';
import InputReusable from '../Reusables/Input/Input';

function Login() {
  const [showPass, setShowPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const submitLogin = async () => {
    const { email, password } = loginData;
    const response = await login(email, password);
    if (response?.error) return setErrorMessage(true);
    localStorage.setItem('user', JSON.stringify(response.user));
    window.location.reload();
  };

  return (
    <div className="tw-flex tw-flex-wrap tw-h-screen">
      <div className="tw-pt-6 lg:tw-pt-16 tw-pb-6 tw-w-full lg:tw-w-1/2">
        <div className="tw-max-w-md tw-mx-auto">
          <div className="tw-mb-6 lg:tw-mb-20 tw-w-full tw-px-3 tw-flex tw-items-center tw-justify-between">
            <a className="tw-text-3xl tw-font-bold tw-leading-none" href="#">
              <img className="tw-h-12" src={LogoISC} alt="" width="auto" />
            </a>
          </div>
          <div>
            <div className="tw-mb-6 tw-px-3">
              <h3 className="tw-text-2xl tw-font-bold tw-text-primary_color_shadow">Login</h3>
            </div>
            <form data-bitwarden-watching="1">
              {errorMessage && emailOrPassWrong(setErrorMessage)}
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <InputReusable
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                  type="email"
                  name="email"
                  value={loginData.email}
                  onChange={(e) => handleLogin(e, loginData, setLoginData)}
                  placeholder="nome@email.com"
                />
                <img
                  className="tw-h-6 tw-w-6 tw-ml-4 tw-my-auto tw-text-gray-300"
                  src={EmailIcon}
                />
              </div>
              <div className="tw-mb-6 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-rounded">
                <InputReusable
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                  type={!showPass ? 'password' : 'text'}
                  name="password"
                  value={loginData.password}
                  onChange={(e) => handleLogin(e, loginData, setLoginData)}
                  placeholder="Digite sua senha"
                />
                <button type="button" onClick={() => setShowPass(!showPass)}>
                  <img
                    className="tw-h-6 tw-w-6 tw-ml-4 tw-my-auto tw-text-gray-300"
                    src={!showPass ? PasswordEyeOff : PasswordEye}
                  />
                </button>
              </div>
              <div className="tw-px-2 tw-text-center">
                <ButtonReusable
                  className="tw-mb-2 tw-w-full tw-py-4 tw-bg-primary_color hover:tw-bg-primary_color_shadow tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200"
                  text="Login"
                  onClick={submitLogin}
                />
              </div>
            </form>
            <span className="tw-flex tw-justify-center">
              2022 © ISC by Tegrus - All rights reserved.
            </span>
          </div>
        </div>
      </div>
      <div className="tw-hidden lg:tw-block tw-relative tw-w-full lg:tw-w-1/2 tw-bg-primary_color">
        <div
          className="tw-absolute tw-bottom-0 tw-inset-x-0 tw-mx-auto tw-mb-12 tw-max-w-xl tw-text-center"
          style={{ zIndex: 10 }}
        >
          <img className="lg:tw-max-w-xl tw-mx-auto" src={LoginIllustration} />
          <h2 className="tw-mb-2 tw-text-2xl tw-text-white tw-font-bold">
            Crie formuláros com o ISC
          </h2>
          <div className="tw-max-w-lg tw-mx-auto">
            <p className="tw-mb-6 tw-text-gray-50 tw-leading-loose">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur nisl
              sodales egestas lobortis.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
