import CardReusable from '../Reusables/Card/Card';
import { useForms } from '../../hooks/useForms';
import { useModal } from '../../hooks/useModal';
import HorizontalNavbarReusable from '../Reusables/Navbar/HorizontalNavbar';
import ModalNewForm from '../Formio/modalNewForm/index';

function FormsDashboard() {
  const { forms } = useForms();
  const { openModal, closeModal, isOpen } = useModal();

  const notFoundForms = (
    <div className="tw-w-full tw-flex tw-justify-center">
      <div
        className="tw-flex tw-items-center tw-bg-blue-500 tw-text-white tw-text-sm tw-font-bold tw-px-4 tw-py-3 tw-rounded"
        role="alert"
      >
        <svg
          className="tw-fill-current tw-w-4 tw-h-4 tw-mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
        </svg>
        <p>Não existem formulários cadastrados</p>
      </div>
    </div>
  );

  const dateOrder = forms.sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return (
    <>
      <HorizontalNavbarReusable onButtonClick={() => openModal()} />

      <ModalNewForm isOpen={isOpen} closeModal={closeModal} />

      <section className="tw-py-8">
        <div className="tw-container tw-px-2 tw-mx-auto">
          <div className="tw-flex tw-flex-wrap">
            {forms.length > 0
              ? dateOrder.map(({ name, createdAt, enable, id, updatedAt, submissions }) => (
                  <CardReusable
                    key={`card-${id}`}
                    id={id}
                    submissionsCount={submissions.count}
                    name={name}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    enable={enable}
                  />
                ))
              : notFoundForms}
          </div>
        </div>
      </section>
    </>
  );
}

export default FormsDashboard;
