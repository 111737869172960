import PublishISC from '../../../PublishISC/Index';

export default function ModalPublishIsc({ showModal, setShowModal, id }) {
  return (
    <>
      {showModal ? (
        <>
          <div className="tw-justify-center tw-flex tw-overflow-x-hidden tw-overflow-y-auto tw-fixed tw-inset-3 tw-z-50 tw-outline-none focus:tw-outline-none tw-h-full">
            <div className="tw-relative tw-w-auto tw-max-w-3xl ">
              <div className="tw-border-0 tw-rounded-lg tw-shadow-lg tw-relative tw-flex tw-flex-col tw-w-full tw-bg-white tw-outline-none focus:tw-outline-none ">
                <div className="tw-flex tw-items-start tw-justify-between tw-p-5 tw-border-b tw-border-solid tw-border-slate-200 tw-rounded-t">
                  <h3 className="tw-text-3xl tw-font-semibold">Como Publicar</h3>
                  <button
                    type="button"
                    className=" tw-rounded-full tw-p-2 tw-inline-flex tw-justify-center tw-text-red-400 hover:tw-text-white hover:tw-bg-navbar_color focus:tw-outline-none focus:tw-ring-2"
                    onClick={() => setShowModal(false)}
                  >
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="tw-relative tw-p-6 tw-flex-auto">
                  <PublishISC idForm={id} />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 tw-bg-black"></div>
        </>
      ) : null}
    </>
  );
}
