function BreadcrumbsReusable({ menuatual, imagebreadcrumb }) {
  return (
    <nav className="tw-flex tw-py-3 tw-px-5 tw-text-gray-400" aria-label="Breadcrumb">
      <ol className="tw-inline-flex tw-items-center tw-space-x-1 md:tw-space-x-3">
        <li className="tw-inline-flex tw-items-center">
          <a
            href="/"
            className="tw-inline-flex tw-items-center tw-text-sm hover:tw-no-underline hover:tw-text-primary_color "
          >
            Início
          </a>
        </li>
        <li>
          <div className="tw-flex tw-items-center">
            <svg
              className="tw-w-6 tw-h-6 tw-text-gray-200"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <a
              href="#"
              className="tw-ml-1 tw-text-sm tw-font-medium md:tw-ml-2 tw-text-primary_color hover:tw-no-underline"
            >
              {imagebreadcrumb}
              {menuatual}
            </a>
          </div>
        </li>
      </ol>
    </nav>
  );
}

export default BreadcrumbsReusable;
