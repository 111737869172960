import { useContext } from 'react';
import MyContext from '../../Context/MyContext';
import { CopyBlock, dracula } from 'react-code-blocks';
// import IdCopyForms from '../../assets/images/idcopyforms.png';

const baseURL = process.env.REACT_APP_CDN_URL;

function PublishISC({ idForm }) {
  const { forms } = useContext(MyContext);

  const formObj = forms.find(({ id }) => id === idForm);

  const idNavegg = formObj.idNaveggAccount;
  const idGtm = formObj.idGTMContainer;

  const env = JSON.parse(localStorage.getItem('env'));

  let formRenderAmbient = '';
  if (env.env === 'development') {
    formRenderAmbient = 'dev';
  }

  if (env.env === 'production') {
    formRenderAmbient = 'prod';
  }

  const formDiv = `<div id="formio" data-form-id="${idForm}"></div>`;
  let body = `<script async type="module" crossorigin src="${baseURL}/isc-render/${formRenderAmbient}/${env.iscRender}/index.js`;

  if (idNavegg && idGtm) {
    body = `${body}?gtm=${idGtm}&navegg=${idNavegg}"></script>`;
  } else if (idNavegg) {
    body = `${body}?navegg=${idNavegg}"></script>`;
  } else if (idGtm) {
    body = `${body}?gtm=${idGtm}"></script>`;
  }

  const head = `<link rel="stylesheet" href="${baseURL}/isc-render/${formRenderAmbient}/${env.iscRender}/index.css" />
${body}`;

  return (
    <div className="tw-p-5">
      <ul className="tw-text-lg tw-mb-5 tw-list-decimal tw-list-inside">
        <li>
          <span className="tw-text-lg tw-mb-5">
            Cole a linha de código abaixo onde o formulário deve ser renderizado. Observe que deve
            estar dentro da tag <strong className="tw-text-[#ff79c6]">&lt;body&gt;</strong>
          </span>
          <div className="tw-mt-4"></div>
          <CopyBlock
            text={formDiv}
            language="html"
            theme={dracula}
            showLineNumbers={false}
            codeBlock={true}
          />
        </li>
        <div className="tw-mt-4"></div>
        <li>
          <span className="tw-text-lg tw-mb-5">
            Cole as duas linhas de código abaixo no início do&nbsp;
            <strong className="tw-text-[#ff79c6]">&lt;head&gt;</strong> do seu site
          </span>
          <div className="tw-mt-4"></div>
          <CopyBlock
            text={head}
            language="html"
            theme={dracula}
            showLineNumbers={false}
            codeBlock={true}
          />
        </li>
      </ul>
    </div>
  );
}

export default PublishISC;
