import baseEditForm from 'formiojs/components/_classes/component/Component.form';

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          {
            //
            key: 'placeholder',
            ignore: true,
          },
          {
            key: 'tabindex',
            ignore: true,
          },
          {
            key: 'labelposition',
            ignore: true,
          },
          {
            key: 'hidden',
            ignore: true,
          },
          {
            key: 'description',
            ignore: true,
          },
          {
            key: 'tooltip',
            ignore: true,
          },
          {
            //
            type: 'textfield',
            input: true,
            ignore: true,
            label: 'My Custom Setting',
            weight: 12,
            key: 'myCustomSetting', //
          },
        ],
      },
      {
        key: 'data',
        components: [],
      },
      {
        key: 'validation',
        components: [],
      },
      {
        key: 'api',
        components: [],
      },
      {
        key: 'conditional',
        components: [],
      },
      {
        key: 'logic',
        components: [],
      },
    ],
    ...extend
  );
};
