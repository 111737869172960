import Modal from 'react-modal';
import { customStyles } from './customStyles';
import CloseButtonReusable from '../Button/CloseButton';
import './Modal.scss';

function ModalComponent({ children, title, isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="title_close_button">
        <h3 className="title">{title}</h3>
        <CloseButtonReusable closeModal={closeModal} />
      </div>
      {children}
    </Modal>
  );
}

export default ModalComponent;
