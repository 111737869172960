import { useContext, useState } from 'react';
import ButtonReusable from '../../Reusables/Button/Button';
import MyContext from '../../../Context/MyContext';
import InputReusable from '../../Reusables/Input/Input';
import ModalComponent from '../../Reusables/Modal/Index';
import LabelReusable from '../../Reusables/Label/Label';
import OptionsSelected from './optionsSelected';
import 'react-toastify/dist/ReactToastify.css';
import editFormValidation from './validation/editFormValidation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import moment from 'moment'

function ModalEditForm({ currentForm, onSave, onIntegrationSync, isOpen, closeModal }) {
  const [form, setForm] = useState(currentForm);
  const { integration } = useContext(MyContext);

  const handleFormChange = ({ target }) => {
    if (target === undefined) return;

    const { name, value } = target;

    switch (name) {
      case 'components':
      case 'actions':
        setForm((prev) => ({
          ...prev,
          [name]: value && value.length > 0 ? JSON.parse(value) : undefined,
        }));
        break;
      default:
        setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSync = async (e) => {
    e.preventDefault();

    onIntegrationSync(form.id);
  }

  const handleSubmit = async (e, values) => {
    e.preventDefault();

    for (const [name, value] of Object.entries(values)) {
      if (name === 'submissionDelay' || name === 'retryDelay') {
        values[name] = parseInt(value);
      }
    }

    onSave({ ...form, ...values });

    closeModal();
  };

  const getLastSyncDate = () => {
    const date = integration[0]?.updatedAt;
    return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : "";
  }

  return (
    <>
      <ModalComponent title="Editando Formulário" isOpen={isOpen} closeModal={closeModal}>
        <Formik
          validationSchema={editFormValidation}
          initialValues={{
            name: form.name,
            submissionDelay: form.submissionDelay,
            retryDelay: form.retryDelay,
          }}
        >
          {({ values }) => (
            <Form className="tw-max-h-[500px]">
              <div className="tw-flex">
                <LabelReusable name="Nome" />
                <span className="tw-text-[#ef4444]">*</span>
              </div>
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <Field
                  type="text"
                  name="name"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>
              <ErrorMessage
                className="tw-text-[#ef4444] tw-text-center mb-2 tw-text-sm"
                component="div"
                name="name"
              />
              <div className="tw-flex">
                <LabelReusable name="Atraso em ms para enviar" />
                <span className="tw-text-[#ef4444]">*</span>
              </div>
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <Field
                  type="text"
                  name="submissionDelay"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>
              <ErrorMessage
                className="tw-text-[#ef4444] tw-text-center mb-2 tw-text-sm"
                component="div"
                name="submissionDelay"
              />
              <div className="tw-flex">
                <LabelReusable name="Atraso de repetição em ms" />
                <span className="tw-text-[#ef4444]">*</span>
              </div>
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <Field
                  type="text"
                  name="retryDelay"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>
              <ErrorMessage
                className="tw-text-[#ef4444] tw-text-center mb-2 tw-text-sm"
                component="div"
                name="retryDelay"
              />

              <LabelReusable name="Componentes" />
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <InputReusable
                  type="text"
                  name="components"
                  value={form.components ? JSON.stringify(form.components) : undefined}
                  onChange={handleFormChange}
                  placeholder="Digite os componentes do seu formulário"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>

              <LabelReusable name="Ações" />
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <InputReusable
                  type="text"
                  name="actions"
                  value={form.actions ? JSON.stringify(form.actions) : undefined}
                  onChange={handleFormChange}
                  placeholder="Digite as ações"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>

              <LabelReusable name="ID Container GTM" />
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <InputReusable
                  type="text"
                  name="idGTMContainer"
                  value={form.idGTMContainer}
                  onChange={handleFormChange}
                  placeholder="Digite o ID do Container GTM"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>

              <LabelReusable name="ID Conta Navegg" />
              <div className="tw-mb-3 tw-flex tw-p-4 tw-mx-2 tw-bg-primary_color_light tw-roundedd">
                <InputReusable
                  type="text"
                  name="idNaveggAccount"
                  value={form.idNaveggAccount}
                  onChange={handleFormChange}
                  placeholder="Digite o ID da Conta Navegg"
                  className="tw-w-full tw-text-xs tw-bg-primary_color_light tw-outline-none"
                />
              </div>

              <LabelReusable name="Integração com Pipefy" />
              <div className="tw-w-full tw-mb-3 tw-ml-auto tw-items-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2">
                <OptionsSelected
                  options={integration}
                  value={form.idPipefyIntegration}
                  onChange={handleFormChange}
                />
              </div>

              <LabelReusable name="Pipes" />
              <div className="tw-flex tw-justify-between tw-mb-3 tw-ml-auto tw-items-center tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-sm tw-font-medium">
                <p>Ultima sincronização: {getLastSyncDate(integration)}</p>
                <ButtonReusable
                  text="Sincronizar"
                  onClick={(e) => handleSync(e)}
                  className="tw-mb-2 tw-w-20 tw-bg-navbar_color hover:tw-bg-primary_color tw-text-sm tw-transition tw-duration-200"
                  />
              </div>

              <ButtonReusable
                text="Editar"
                type="submit"
                onClick={(e) => handleSubmit(e, values)}
                className="tw-mb-2 tw-w-full tw-py-4 tw-bg-navbar_color hover:tw-bg-primary_color tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200"
              />
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
}

export default ModalEditForm;
