import classNames from 'classnames';

function ButtonReusable({ onClick, text, className, type }) {
  const defaultStyle =
    'tw-py-2 tw-rounded tw-text-sm tw-font-bold tw-text-gray-50 tw-transition tw-duration-200 hover:tw-shadow-lg';
  return (
    <span>
      <button
        className={classNames(defaultStyle, className)}
        type={type ? type : 'button'}
        onClick={onClick}
      >
        {text}
      </button>
    </span>
  );
}

export default ButtonReusable;
