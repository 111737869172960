import { useContext } from 'react';
import MyContext from '../Context/MyContext';

export function useTabs() {
  const { setActiveTab, activeTab } = useContext(MyContext);

  return {
    setActiveTab,
    activeTab,
  };
}
