import { Routes, Route } from 'react-router-dom';

import './App.css';

import Login from './components/Login/Index';
import Home from './components/Home/Index';
import FormsDashboard from './components/FormsDashboard/Index';
import NewForm from './components/Formio/newForm/Index';
import FormRenderer from './components/FormRenderer';
import Invite from './components/Invite/Index';
import Integrations from './components/Integrations/Index';
import Clients from './components/Clients/Index';
import { ProtectedRoutes, ProtectedLogin } from './utils/ProtectedRoutes';
import Settings from './components/Settings/Index';
import PublishISC from './components/PublishISC/Index';

function App() {

  console.log(`ISC version - ${process.env.REACT_APP_VERSION}`);
  
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />}>
          <Route index element={<FormsDashboard />} />
          <Route path="/convidar" element={<Invite />} />
          <Route path="/integracoes" element={<Integrations />} />
          <Route path="/clientes" element={<Clients />} />
          <Route path="/configuracoes" element={<Settings />} />
          <Route path="/publicarisc" element={<PublishISC />} />
          <Route path="/form/editar/:id" element={<NewForm />} />
          <Route path="/form/visualizar/:id" element={<FormRenderer />} />
        </Route>
      </Route>
      <Route element={<ProtectedLogin />}>
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}

export default App;
