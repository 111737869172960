import { useContext, useState } from 'react';
import MyContext from '../../Context/MyContext';
import { deleteClient } from '../../services/api';
import DeleteClient from '../Reusables/Modal/modalDeleteAlert/Index';

function TableItemsClients({ name, id, openModal }) {
  const { clients, setClients, setEdit, notifySuccess, setCurrentClient } = useContext(MyContext);

  const [showModal, setShowModal] = useState(false);

  const clientDelete = async () => {
    await deleteClient(id);
    setClients(clients.filter((client) => client.id !== id));
    setShowModal(false);
    notifySuccess('Cliente deletado com sucesso!');
  };

  const editClient = async () => {
    const findClient = clients.find((cli) => cli.id === id);
    setCurrentClient(findClient);
    setEdit(true);
    openModal();
  };

  return (
    <>
      <DeleteClient
        showModal={showModal}
        setShowModal={setShowModal}
        ConfirmDelete={clientDelete}
      />
      <tr className="tw-text-xs tw-border-b tw-border-primary_color_light">
        <td className="tw-flex tw-ml-7 tw-pt-3">
          <div className="">
            <p className="tw-font-medium tw-w-40">{name}</p>
          </div>
        </td>
        <td className="tw-text-left">
          <a className="tw-inline-block tw-mr-00 hover:tw-cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              className="tw-h-5 tw-w-5"
              viewBox="0 0 20 20"
              fill="#37B6ED"
              onClick={editClient}
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
          </a>

          <a className="tw-inline-block hover:tw-cursor-pointer tw-mt-2">
            <svg
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              className="tw-h-5 tw-w-5"
              viewBox="0 0 20 20"
              fill="#E85444"
              onClick={() => setShowModal(true)}
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </td>
      </tr>
    </>
  );
}
export default TableItemsClients;
