function OptionsSelected({ options, value, onChange }) {
  return (
    <select
      name="idPipefyIntegration"
      className="tw-w-full tw-bg-transparent tw-cursor-pointer"
      value={value}
      onChange={onChange}
    >
      <option value={'null'}></option>
      {options.map((integration) => (
        <option key={integration.name} value={integration.id}>
          {integration.name}
        </option>
      ))}
    </select>
  );
}

export default OptionsSelected;
